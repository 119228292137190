.center-screen {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100vw;
    height: 100vh;
}